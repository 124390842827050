table {
  &.table-core {
    th {
      font-size: 0.8rem;
      font-weight: bold;
      padding: 0.8rem 2rem;
      color: rgba(0, 0, 0, 0.54);

      i {
        transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        opacity: 0;
        font-size: 1.2rem;

        &.show {
          opacity: 1;
        }

        &.desc {
          transform: rotate(0);
        }

        &.asc {
          transform: rotate(180deg);
        }
      }
    }

    td {
      padding: 1rem 2rem;
      font-size: 0.8rem;
      font-weight: 400;

      a {
        color: #1a9bf9;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &.sortable {
    th {
      cursor: pointer;

      &:hover:not(.disabled) {
        color: rgba(0, 0, 0, 0.87);
      }

      &.disabled {
        cursor: default;
      }
    }
  }
}
