main {
  position: absolute;
  left: 0;
  top: 73px;
  right: 0;
  bottom: 0;
  overflow: auto;

  @media (max-width: 600px) {
    top: 57px;
  }
}

.hidden-sn main {
  padding: 15px 10px 5px 10px;

  @include media-breakpoint-down(sm) {
    padding: 0;
  }
}
