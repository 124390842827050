$alwyn-font-path: "../fonts/" !default;
$zindex-modal-backdrop: 1040;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;
