body {
  font-family: "Alwyn", sans-serif;
  font-weight: 300;
}

strong {
  font-weight: 600;
}

table.table a {
  color: $primary-color;
  &:hover {
    text-decoration: underline;
  }
}

table.simple-list {
  th {
    text-align: center;
    font-weight: 600;

    &.button {
      width: 100px;
    }
  }
  td {
    padding: 0.25rem 0.75rem;
    vertical-align: middle;

    &:first-of-type {
      text-align: center;
      font-weight: bold;
    }
    &.button {
      padding: 0.25rem;
      text-align: center;
    }
    &.loading {
      padding: 0.75rem;
    }
  }
}

.modal {
  z-index: $zindex-modal-backdrop;
  overflow-y: auto;
}

.invalid-feedback {
  display: block;
}
