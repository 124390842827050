.info-ue {
  max-width: 700px;
  width: 100%;
  margin: 2rem auto;
  font-size: 1.2rem;
  line-height: 1.5;
  text-align: center;

  img {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
  }

  h1 {
    font-weight: bold;
    color: #0070c0;
    font-size: 1em;
  }

  p {
    font-size: 1em;
  }
}

.ue-line-1 {
  background-color: #01ad51;
  height: 2rem;
}

.ue-line-2 {
  background-color: #0671be;
  height: 2rem;
  margin-bottom: 5px;
}
